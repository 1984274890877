<template>
  <div class="list overflow-y-auto" ref="listElem" @contextmenu.prevent>
    <client-only> <!-- todo: do we need fallback for ssr?  -->
      <slot name="header" />
      <div :style="wrapperStyle" class="list-wrapper flex flex-col" :class="gridGapClass">
        <div v-for="section in sectionsInView" :id="section.id" :key="section.id" class="list-section">
          <div :class="[containerClass, gridGapClass]">
            <div v-for="item in section.items" :class="itemClass">
              <slot :item="item" />
            </div>
          </div>
        </div>
      </div>
    </client-only>

  </div>
</template>

<script setup>
  import throttle from 'lodash.throttle';

  const emit = defineEmits(['approachBottom', 'sectionsUpdated']);
  const props = defineProps({
    items: Array,
    emitOnDistanceFromBottom: {
      type: Number,
      default: 500
    },
    scrollerElem: [null, Object],
    disableApproachBottom: Boolean,

    containerClass: {
      type: String,
      default: 'grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 grid-rows-auto'
    },
    gridGapClass: {
      type: String,
      default: 'gap-2 sm:gap-4'
    },
    sectionSize: {
      type: Number,
      default: 48
    },

    itemClass: String
  });

  const listElem = ref(null);

  //sectioning
  const sections = computed(() => {
    let sectionCounter = props.sectionSize;
    let currentSection;

    return props.items.reduce((allSections, item, i) => {
      if (sectionCounter === props.sectionSize) {
        const sectionIndex = i / props.sectionSize;

        sectionCounter = 0;
        currentSection = {
          id: `section-${sectionIndex}`,
          index: sectionIndex,
          items: []
        };

        allSections.push(currentSection);
      }

      currentSection.items.push(item);
      sectionCounter++;

      return allSections;
    }, []);
  });

  const sectionHeight = ref(0);
  const scrollingElem = computed(() => props.scrollerElem || listElem.value);
  const {y: scrollPosition} = useScroll(scrollingElem);
  const {width: listWidth, height: listHeight} = useElementSize(scrollingElem);
  const minIndexInView = ref(0);
  const maxIndexInView = ref(0);

  const bufferSectionsCount = computed(() => {
    if (!listHeight.value || !sectionHeight.value) {
      return 2;
    }

    return Math.ceil(listHeight.value / sectionHeight.value) + 1;
  });

  const sectionsInView = computed(() => sections.value.slice(minIndexInView.value, maxIndexInView.value + 1));
  const wrapperStyle = computed(() => {
    const minIndex = sectionsInView.value[0].index;
    const maxIndex = sectionsInView.value[sectionsInView.value.length - 1].index;
    const marginTop = `${minIndex * sectionHeight.value}px`;
    const marginBottom = `${(sections.value.length - 1 - maxIndex) * sectionHeight.value}px`;

    return {marginTop, marginBottom};
  });

  function updateSectionsInViewIndexes() {
    const sectionIndexToBringToView = Math.round(scrollPosition.value / (sectionHeight.value || 1));
    const buffer = bufferSectionsCount.value;


    minIndexInView.value = Math.max(Math.min(sectionIndexToBringToView - buffer, sections.value.length - (buffer + 1)), 0);
    maxIndexInView.value = Math.min(Math.max(sectionIndexToBringToView + buffer, buffer), sections.value.length - 1);
  }

  const updateSectionHeight = throttle(() => {
    const {height} = useElementSize(listElem.value.querySelector('.list-wrapper > div:first-child'));
    sectionHeight.value = height.value;
  }, 100);

  //scroll bottom
  const detectApproachBottom = throttle(() => {
    if (props.disableApproachBottom || !scrollingElem.value) {
      return;
    }

    const viewPortBottom = scrollPosition.value + listHeight.value;
    const scrollHeight = scrollingElem.value.scrollHeight;

    if (viewPortBottom >= scrollHeight - props.emitOnDistanceFromBottom) {
      emit('approachBottom');
    }

  }, 100);

  watch(sectionsInView, () => emit('sectionsUpdated'));

  onMounted(async () => {
    await nextTick();

    watch(
      listWidth,
      () => {
        updateSectionHeight();
        updateSectionsInViewIndexes();
        detectApproachBottom();
      },
      {
        immediate: true
      }
    );

    watch(scrollPosition, () => {
      updateSectionsInViewIndexes();
      detectApproachBottom();
    });
  });

</script>
